import { ref, computed } from 'vue'

export const jobSourceTypeList = ref([
  { name: '内部', value: 'internal' },
  { name: '拉勾', value: 'lagou' },
  { name: 'BOSS直聘', value: 'zhipin' },
  { name: '智联招聘', value: 'zhaopin' },
  { name: '其他', value: 'other' }
])

export const jobSortList = ref([
  { label: '创建时间-倒序', value: '-createTime' },
  { label: '创建时间-正序', value: 'createTime' },
  { label: '更新时间-倒序', value: '-updateTime' },
  { label: '更新时间-正序', value: 'updateTime' },
  { label: '置顶-倒序', value: '-digScore' },
  { label: '置顶-正序', value: 'digScore' }
])

export const jobTagList = ['编辑推荐', '双休', '五险一金', '包吃住', '高薪']

export const dutyTypeList = ref(['全职', '兼职', '远程'])
export const benefitList = ref([
  '五险一金',
  '年底双薪',
  '包吃',
  '包住',
  '带薪年假',
  '通讯补助',
  '交通补助',
  '免费班车',
  '员工旅游'
])
export const educationList = ref(['初中', '中专', '高中', '大专', '本科', '其他'])
