<template lang="pug">
el-drawer(:modelValue="isShow" title="选择公司" direction="rtl" :before-close="handleClose")
  .container
    .filter
      el-input(v-model="filter.search" size="mini" placeholder="搜索名称")
      el-button(icon="el-icon-search" type="primary" size="mini" style="margin-left:10px;" @click="handleSearch")
      el-button(icon="el-icon-refresh" type="warning" size="mini" @click="handleReset")
    .list
      .item(v-for="i in list" :class="{ isActive: isActive(i) }" @click="handlePickItem(i)")
        .displayInfo()
          div {{ i.name }}
        .extentInfo
    .actionBar
      .pagination
        el-pagination(layout="prev, pager, next" background v-model:page-size="pagination.size" :total="pagination.total" v-model:current-page="pagination.page" @current-change="getList")
      el-button(type="primary" style="width:100%" @click="handleSubmitPick" :disabled="!currentPickId") 确定
</template>

<script setup>
import { ref, inject, computed, watch, onMounted } from 'vue'
const { fetch, isDebug, message } = inject('global')

const props = defineProps({
  isShow: {
    type: Boolean,
    default: false
  },
  initSearchValue: {
    type: String,
    default: ''
  }
})

const emits = defineEmits(['success', 'update:isShow'])

const filter = ref({
  search: ''
})
const list = ref([])
const pagination = ref({
  page: 1,
  size: 10,
  total: 100
})
const currentPickId = ref('')
function isActive(val) {
  return currentPickId.value === val._id
}

function handlePickItem(val) {
  if (currentPickId.value === val._id) return
  currentPickId.value = val._id
}

function handleSearch() {
  currentPickId.value = ''
  pagination.value.page = 1
  getList()
}
function getList() {
  let params = {
    ...pagination.value
  }
  if (filter.value?.search) {
    params.search = filter.value.search
  }
  fetch
    .get(`/company`, {
      params
    })
    .then(res => {
      list.value = res.list
      pagination.value.total = res.page.total
    })
}
function initData() {
  filter.value.search = props.initSearchValue || ''
  currentPickId.value = ''
  pagination.value.page = 1
  getList()
}
function handleReset() {
  filter.value.search = ''
  currentPickId.value = ''
  pagination.value.page = 1
  getList()
}

function handleClose() {
  emits('update:isShow', false)
}
async function handleSubmitPick() {
  if (!currentPickId.value) return
  const result = list.value.find(i => i._id === currentPickId.value)
  if (!result) return
  emits('success', result)
  handleClose()
}

watch(
  () => props.isShow,
  val => {
    if (val) {
      initData()
    }
  }
)

onMounted(() => {})
</script>

<style lang="less" scoped>
.container {
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.filter {
  padding: 0 20px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
}
.list {
  flex-grow: 1;
  overflow-y: auto;
  margin: 20px 0;
  .item {
    cursor: pointer;
    padding: 5px 20px;
    border: 1px solid transparent;
    .displayInfo {
      display: flex;
      justify-content: space-between;
    }
    .extentInfo {
      justify-content: space-between;
      font-size: 12px;
      color: #ccc;
      margin-top: 10px;
    }
    &:hover {
      color: var(--color-primary);
      border: 1px solid var(--color-primary);
    }
    &.isActive {
      background: var(--color-primary);
      color: white;
    }
  }
}
.actionBar {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
  .pagination {
    margin-bottom: 20px;
  }
}
</style>
