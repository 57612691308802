<template lang="pug">
.page
  el-card(header="编辑职位信息")
    el-form(label-width="7em")
      el-form-item(label="来源" required)
        el-radio-group(v-model="formData.sourceInfo.type")
          el-radio-button(v-for="i in jobSourceTypeList" :label="i.value") {{ i.name }}
      el-form-item(label="源链接" v-if="formData.sourceInfo.type !== 'internal'")
        el-input(v-model="formData.sourceInfo.link" style="width:300px")
      el-form-item(label="职位名称" required)
        el-input(v-model="formData.name" style="width:300px")
      el-form-item(label="职位简介")
        el-input(type="textarea" v-model="formData.desc" style="width:300px")
      el-form-item(label="所属公司")
        el-tag(v-if="formData?.companyInfo?.name") {{ formData?.companyInfo?.name }}
        el-button(type="primary" size="mini" style="margin-left:10px;" @click="isShowCompanySelect = true") 选择所属公司
      el-form-item(label="职位类别" required)
        el-tag(v-if="formData?.jobCategoryInfo?.name") {{ formData?.jobCategoryInfo?.name }}
        el-button(type="primary" size="mini" style="margin-left:10px;" @click="isShowJobCategorySelect = true") 选择职位类别
      el-form-item(label="地区" required)
        el-select(v-model="formData.cityList" multiple)
          el-option(v-for="i in cityList" :label="i.name" :value="i._id")
      el-form-item(label="属性" required)
        el-radio-group(v-model="formData.dutyType")
          el-radio-button(label="全职")
          el-radio-button(label="兼职")
          el-radio-button(label="远程")
      el-form-item(label="薪资范围" v-if="formData.dutyType==='全职'" required)
        el-input-number(controls-position="right" v-model="formData.payoffInfo.start" :min="0")
        span(style="margin:0 10px;") 至
        el-input-number(controls-position="right" v-model="formData.payoffInfo.end" :min="0")
      el-form-item(label="薪资" v-else style="width:300px;" required)
        el-input(v-model="formData.payoffInfo.other")
      el-form-item(label="需求人数" style="width:300px;" required)
        el-input-number(v-model="formData.requireInfo.number" :min="0" controls-position="right")
      el-form-item(label="学历要求" style="width:300px;" required)
        el-select(v-model="formData.requireInfo.education")
          el-option(label="不限" value="")
          el-option(v-for="i in educationList" :label="i" :value="i")
      el-form-item(label="工作年限")
        el-input-number(controls-position="right" v-model="formData.requireInfo.expMin" :min="0")
        span(style="margin:0 10px;") 至
        el-input-number(controls-position="right" v-model="formData.requireInfo.expMax" :min="0")
      el-form-item(label="福利标签")
        el-select(v-model="formData.benefitList" multiple filterable allow-create)
          el-option(v-for="i in benefitList" :label="i" :value="i")
      el-form-item(label="介绍图片")
        jj-imageList(v-model="formData.imageList" type="job")
      el-form-item(label="详细内容" required)
        com-editor(v-model="formData.content" type="full" style="width:700px")
      .subTitle 联系方式信息
      el-form-item(label="地址")
        el-input(v-model="formData.contactInfo.address" style="width:300px;")
      el-form-item(label="联系人")
        el-input(v-model="formData.contactInfo.people" style="width:300px;")
      el-form-item(label="电话")
        el-input(v-model="formData.contactInfo.tel" style="width:300px;")
      el-form-item(label="邮箱")
        el-input(v-model="formData.contactInfo.email" style="width:300px;")
      el-form-item(label="微信")
        el-input(v-model="formData.contactInfo.wechat" style="width:300px;")
      el-form-item(label="其他联系方式")
        el-input(v-model="formData.contactInfo.other" style="width:300px;")
      el-form-item()
        el-button(type="primary" @click="handleSubmit") 保存

  comCompanySelect(v-model:isShow="isShowCompanySelect" @success="onCompanySelect")
  comJobCategorySelect(v-model:isShow="isShowJobCategorySelect" @success="onJobCategorySelect")

</template>

<script setup>
import { inject, onMounted, ref } from 'vue'
import comEditor from '@/component/editor/index.vue'
import comCompanySelect from '@/component/select/company.vue'
import comJobCategorySelect from '@/component/select/jobCategory.vue'
import { jobSourceTypeList, benefitList, educationList } from '@/dict/job'
import { getCityList, cityList } from '@/provider/global'

const { router, route, fetch, message } = inject('global')

const isShowCompanySelect = ref(false)
const isShowJobCategorySelect = ref(false)

const formData = ref({
  _id: '',
  name: '',
  desc: '',
  imageList: [],
  content: '',
  sourceInfo: {
    type: 'internal',
    link: ''
  },
  cityList: [],
  tagList: [],
  jobList: [],
  company: '',
  companyInfo: {},
  jobCategory: '',
  jobCategoryInfo: {},
  dutyType: '兼职',
  contactInfo: {
    address: '',
    people: '',
    tel: '',
    email: '',
    wechat: '',
    other: ''
  },
  payoffInfo: {
    start: 0,
    end: 0,
    other: ''
  },
  requireInfo: {
    number: 0,
    expMin: 0,
    expMax: 0,
    education: ''
  }
})

function handleSubmit() {
  if (!formData.value.name) {
    message.warning('名称不能为空')
  } else if (!formData.value.jobCategory) {
    message.warning('职位类别不能为空')
  } else {
    const { _id, ...payload } = formData.value
    return Promise.resolve()
      .then(() => {
        if (_id) {
          return fetch.put(`/job/${_id}`, {
            ...payload
          })
        } else {
          return fetch.post(`/job`, {
            ...payload
          })
        }
      })
      .then(res => {
        message.warning('保存成功')
        router.push('/job')
      })
  }
}

function onCompanySelect(val) {
  formData.value.companyInfo = val
  formData.value.company = val._id
}
function onJobCategorySelect(val) {
  formData.value.jobCategoryInfo = val
  formData.value.jobCategory = val._id
}

onMounted(() => {
  getCityList()
  if (route.params.id === 'new') {
    formData.value = {
      _id: '',
      name: '',
      desc: '',
      imageList: [],
      content: '',
      sourceInfo: {
        type: 'internal',
        link: ''
      },
      cityList: [],
      tagList: [],
      jobList: [],
      company: '',
      companyInfo: {},
      jobCategory: '',
      jobCategoryInfo: {},
      dutyType: '兼职',
      contactInfo: {
        address: '',
        people: '',
        tel: '',
        email: '',
        wechat: '',
        other: ''
      },
      payoffInfo: {
        start: 0,
        end: 0,
        other: ''
      },
      requireInfo: {
        number: 0,
        expMin: 0,
        expMax: 0,
        education: ''
      }
    }
  } else {
    fetch.get(`/job/${route.params.id}`).then(res => {
      formData.value = res
      formData.value.jobCategoryInfo = res.jobCategory
      formData.value.companyInfo = res.company
      if (!res.payoffInfo) {
        formData.value.payoffInfo = {
          start: 0,
          end: 0,
          other: ''
        }
      }
      if (!res.requireInfo) {
        formData.value.requireInfo = {
          number: 0,
          expMin: 0,
          expMax: 0,
          education: ''
        }
      }
    })
  }
})
</script>

<style lang="less" scoped>
.addItem {
  .flexCenter();
  .border();
  width: 50px;
  height: 50px;
}
.subTitle {
  padding: 20px;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 20px;
  font-size: 22px;
}
</style>
